<template>
    <div>
        <Breadcrumb
          :nameNav="nameNav"
          :itemFilter="itemFilter"
          :searchQuery="searchQuery"
          @itemFilterSearch="addItemFilterSearch"
        />
        <filters
          @people-selected="peopleSelected"
          @date-from="setDateFrom"
          @date-to="setDateTo"
          @type-selected="typeSelected"
          @unit-selected="unitSelected"
        />


        <b-card class="m-2" no-body>
            <div class="p-2">
                <b-row>
                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                        <label class="text-capitalize">{{ $t("table.show") }} </label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label class="text-capitalize">{{ $t("table.entries") }} </label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                :placeholder="$t('table.search')"
                            />
                            <!-- <b-button @click="resetFilters()">
                                {{ $tc('safr.resetFilters', 2) }}
                            </b-button> -->
                        </div>
                    </b-col>
                </b-row>
            </div>

            <b-table
                ref="refListTable"
                class="position-relative"
                :items="fetchList"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                :empty-text="$t('no_record_found')"
                :sort-desc.sync="isSortDirDesc"
            >
                <template #head()="data">
                    <span> {{ $tc(data.label, 1) }}</span>
                </template>

                <template #head(event_id)="data">
                    <span> {{ $tc(data.label, 2) }}</span>
                </template>

                <template #head(source_id)="data">
                    <span> {{ $tc(data.label, 2) }}</span>
                </template>

                <template #cell(eventImage)="data">
                    <b-img
                        :src="data.item.eventImage"
                        width="85"
                        height="100"
                    />
                </template>

                <template #head(type)="data">
                    <span> {{ $tc(data.label, 2) }}</span>
                </template>
            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted text-capitalize">
                            {{ $t('table.showing', {from: dataMeta.from,to: dataMeta.to,of: dataMeta.of}) }}
                        </span>
                    </b-col>
                  <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalList"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BButton,
    // BBadge,
    BDropdown,
    BDropdownItem,
    BPagination
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Filters from './Filters.vue'
import { ref,computed } from '@vue/composition-api'
import useList from './useList'
import axios from '@/libs/axios'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import Breadcrumb from '@/components/Breadcrumb.vue'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BPagination,

        Filters,
        vSelect,

        Breadcrumb
    },

    setup(_, context) {
        const isSidebarActive = ref(false)
        const itemEdit = ref({})
        const isAdd = ref(false)

        const { t } = useI18nUtils()

        const {
            fetchList,
            tableColumns,
            perPage,
            currentPage,
            totalList,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            refetchData,
            formatDateAssigned,
            people_id,
            dateFrom,
            dateTo,
            type,
            unit,

            tenantOptions,
            tenantFilter,
            contentToast
        } = useList()

        //Methods
        const peopleSelected = (peopleId) => {
            people_id.value = peopleId
        }

        const setDateFrom = (date) => {
            dateFrom.value = date
        }

        const setDateTo = (date) => {
            dateTo.value = date
        }

        const typeSelected = (typeValue) => {
            type.value = typeValue
        }

        const unitSelected = (unitValue) => {
            unit.value = unitValue;
        }

        const itemFilter = ref();
        const addItemFilter = (data) => {
          itemFilter.value = data;
        };
        const addItemFilterSearch = (data) => {
          data.forEach((element) => {
            if (nameNav.value === element.text) {
              searchQuery.value = element.searchQuery;
            }
          });
        };
        const nameNav = ref("breadcrumbs.vass.analytics.facial_recognition.sarf.events");

        return {
            // Sidebar
            isSidebarActive,
            isAdd,
            itemEdit,
            //Breadcrumb
            itemFilter,
            addItemFilter,
            nameNav,
            addItemFilterSearch,

            fetchList,
            tableColumns,
            perPage,
            currentPage,
            totalList,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refListTable,
            refetchData,
            formatDateAssigned,
            unit,

            tenantOptions,
            tenantFilter,

            peopleSelected,
            setDateFrom,
            setDateTo,
            typeSelected,
            unitSelected
        }
    }
}
</script>