<template>
    <b-card no-body class="m-2">
        <b-card-header class="pb-50">
            <h5 class="text-capitalize">
                {{ $tc('actions.filter', 1) }}
            </h5>
        </b-card-header>
        <b-card-body>
            <b-row class="align-items-end">
                <b-col
                    cols="12"
                    md="3"
                    lg="3"
                    class="mb-md-0 mb-2"
                >
                    <label>
                        {{ $tc('safr.people', 1) }}
                    </label>
                    <v-select
                        label="name"
                        :placeholder="$tc('safr.placeholderPerson', 2)"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="people"
                        :reduce="({ people_id }) => people_id"
                        :clareable="true"
                        @input="(val) => $emit('people-selected', val)"
                    />
                </b-col>
                <b-col
                    cols="12"
                    md="3"
                    lg="3"
                    class="mb-md-0 mb-2"
                >
                    <label>
                        {{ $tc('safr.from', 1) }}
                    </label>
                    <flat-pickr
                        class="form-control"
                        :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss', time_24hr: true}"
                        :value="date1"
                        :placeholder="$tc('safr.placeholderDate1', 2)"
                        @on-change="(selectedDates, val) => $emit('date-from', val)"
                    />
                </b-col>
                <b-col
                    cols="12"
                    md="3"
                    lg="3"
                    class="mb-md-0 mb-2"
                >
                    <label>
                        {{ $tc('safr.to', 1) }}
                    </label>
                    <flat-pickr
                        class="form-control"
                        :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss', time_24hr: true}"
                        :value="date2"
                        :placeholder="$tc('safr.placeholderDate2', 2)"
                        @on-change="(selectedDates, val) => $emit('date-to', val)"
                    />
                </b-col>
                <b-col
                    cols="12"
                    md="3"
                    lg="3"
                    class="mb-md-0 mb-2"
                >
                <label>
                        {{ $tc('safr.type', 1) }}
                    </label>
                    <v-select
                        :placeholder="$tc('safr.placeholderType', 2)"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="['event','match']"
                        :clareable="true"
                        @input="(val) => $emit('type-selected', val)"
                    />
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col
                    cols="12"
                    md="3"
                    lg="3"
                    class="mb-md-0 mb-2"
                >
                    <label>
                        {{ $tc('safr.units', 1) }}
                    </label>
                    <v-select
                        label="description"
                        :placeholder="$tc('safr.placeholderUnit', 2)"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="units"
                        :reduce="({ id_unit }) => id_unit"
                        :clareable="true"
                        @input="(val) => $emit('unit-selected', val)"
                    />
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '@/libs/axios'
import { onMounted, ref, watch } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BButton,
        vSelect,
        flatPickr
    },
    setup(_, { emit }) {
        onMounted(async () => {
            let requestListPeople  = { driver: 'safr', isList: true };
            let requestPeople      = axios.get('/v1/services/facial-recognition/safr/people', { params: requestListPeople });
            let requestUnits       = axios.get('/v1/services/facial-recognition/safr/events/cameras/tenants');

            await Promise.all([requestPeople,requestUnits])
              .then(responses => {
                  let [responsePeople, responseUnits] = responses;

                  people.value = responsePeople.data.data;
                  units.value  = responseUnits.data.data;
              })
              .catch(error => {
                  people.value = [];
                  units.value  = [];
              });
        });

        //Data
        const people = ref([]);

        const date1 = ref(null);

        const date2 = ref(null);

        const units = ref([]);

        //Methods
        const resetFilters = () => {
            date1.value = null
            date2.value = null
            emit('people-selected', null)
        }

        //Watchers
        watch(date1, () => emit('date-from', date1.value));

        watch(date2, () => emit('date-to', date2.value));

        return {
            date1,
            date2,
            people,
            units,

            resetFilters
        }
    }
}
</script>

<style scoped  lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
