var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"m-2",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"pb-50"},[_c('h5',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.$tc('actions.filter', 1))+" ")])]),_c('b-card-body',[_c('b-row',{staticClass:"align-items-end"},[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"3","lg":"3"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$tc('safr.people', 1))+" ")]),_c('v-select',{attrs:{"label":"name","placeholder":_vm.$tc('safr.placeholderPerson', 2),"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.people,"reduce":function (ref) {
	var people_id = ref.people_id;

	return people_id;
},"clareable":true},on:{"input":function (val) { return _vm.$emit('people-selected', val); }}})],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"3","lg":"3"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$tc('safr.from', 1))+" ")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ enableTime: true, dateFormat: 'Y-m-d H:i:ss', time_24hr: true},"value":_vm.date1,"placeholder":_vm.$tc('safr.placeholderDate1', 2)},on:{"on-change":function (selectedDates, val) { return _vm.$emit('date-from', val); }}})],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"3","lg":"3"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$tc('safr.to', 1))+" ")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ enableTime: true, dateFormat: 'Y-m-d H:i:ss', time_24hr: true},"value":_vm.date2,"placeholder":_vm.$tc('safr.placeholderDate2', 2)},on:{"on-change":function (selectedDates, val) { return _vm.$emit('date-to', val); }}})],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"3","lg":"3"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$tc('safr.type', 1))+" ")]),_c('v-select',{attrs:{"placeholder":_vm.$tc('safr.placeholderType', 2),"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":['event','match'],"clareable":true},on:{"input":function (val) { return _vm.$emit('type-selected', val); }}})],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"3","lg":"3"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$tc('safr.units', 1))+" ")]),_c('v-select',{attrs:{"label":"description","placeholder":_vm.$tc('safr.placeholderUnit', 2),"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.units,"reduce":function (ref) {
	var id_unit = ref.id_unit;

	return id_unit;
},"clareable":true},on:{"input":function (val) { return _vm.$emit('unit-selected', val); }}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }