import { ref, computed, watch } from '@vue/composition-api';
// import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import moment from 'moment-timezone';
import router from '@/router';
import ability from '@/libs/acl/ability';
import axios from '@/libs/axios';
import { debounce } from 'lodash';

export default function useList() {
    const refListTable = ref(null);

    const toast = useToast();

    const listFilter = ref(null);

    if (router.currentRoute.params.type);
        listFilter.value = parseInt(router.currentRoute.type);

    const tableColumns = [
        {
            label: 'safr.photo',
            key: 'eventImage'
        },
        {
            label: 'safr.person',
            key: 'people.name',
            sortable: true
        },
        {
            label: 'safr.source_id',
            key: 'source_id',
            sortable: true
        },
        {
            label: 'safr.type',
            key: 'type',
            sortable: true
        },
        {
            label: 'safr.master_account',
            key: 'safrMasterAccount.user',
            sortable: true
        },
        {
            label: 'safr.event_id',
            key: 'event_id',
            sortable: true
        },
        {
            label: 'safr.date',
            key: 'date',
            sortable: true
        }
    ];

    const perPage        = ref(10);
    const totalList      = ref(0);
    const currentPage    = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery    = ref('');
    const sortBy         = ref('id');
    const isSortDirDesc  = ref(true);
    const people_id      = ref(null);
    const dateFrom       = ref(null);
    const dateTo         = ref(null);
    const dates          = ref([]);
    const type           = ref(null);
    const unit           = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalList.value,
        }
    });

    const fetchList = async () => {
        let params   = {
            q: searchQuery.value,
            max: perPage.value,
            'page[n]': currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            type: listFilter.value,
            people_id: people_id.value,
            dateFrom: dateFrom.value,
            dateTo: dateTo.value,
            type: type.value,
            unit: unit.value
        }

        let { data } = await axios.get('/v1/services/facial-recognition/safr/events',{ params });
        let { data: dataApi,meta,success } = data;

        if (!success) {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error obteniendo la información',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            });

            return [];
        }

        totalList.value = meta.total;

        return dataApi;
    }

    const formatDateAssigned = value => {
        const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss');
        return formattedDate;
    }

    const refetchData = () => {
        refListTable.value.refresh();
    }

    const contentToast = (message,type) => toast({
        component: ToastificationContent,
        props: {
            title: message,
            icon: 'CheckIcon',
            variant: type
        }
    });


    watch([ listFilter, currentPage, perPage, people_id, type, unit, dateFrom, dateTo ], () => {
        refetchData();
    });

    watch([ searchQuery ], debounce(() => {
      refetchData()
    }, 500))

   /*  watch([dateFrom,dateTo], () => {
        refetchData();
    }) */

    return {
        tableColumns,
        perPage,
        currentPage,
        totalList,
        fetchList,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refListTable,
        formatDateAssigned,
        people_id,
        dateFrom,
        dateTo,
        type,
        unit,

        listFilter,

        refetchData,
        contentToast
    }
}
